const { eventKundefilialeGesetzt } = require("../../common/customEvents");

class KundenfilialeZuordnung extends HTMLElement {
  connectedCallback() {
    this.initEventListeners();
    this.checkSessionStorage();
  }
  
  initEventListeners() {
    window.addEventListener('login.change', () => { this.callKundenZuordnung(); });
  }

  checkSessionStorage() {
    if (sessionStorage.getItem('recallKundenzuordnung') === "true") {
      this.callKundenZuordnung();
    }
  }

  async callKundenZuordnung() {
    try {
      const response = await fetch("/api/rest/kundenfiliale", { method: 'GET', cache: "no-store" });
      if (response.status === 200) {
        eventKundefilialeGesetzt();
        this.clearSessionStorage();
      }
    } catch (err) {
      console.error(err);
    }
  }

  clearSessionStorage() {
    sessionStorage.removeItem('recallKundenzuordnung');
  }

}

if (!customElements.get("kundenfiliale-zuordnung")) {
  customElements.define("kundenfiliale-zuordnung", KundenfilialeZuordnung);
}
document.querySelector('body').insertAdjacentHTML('beforeend', `<kundenfiliale-zuordnung component="kundenfiliale-zuordnung"></kundenfiliale-zuordnung>`);